import Magento from '@/services/Magento'
import Config from '@/config'

const CustomerService = {
  sendCustomerCare ({ params, storeViewCode, typeTpl = 'customerService' }) {
    let formData = new FormData()
    if (params.uploadfile && params.uploadfile.length) {
      params.uploadfile.forEach((f) => {
        formData.append('document', f)
      })
    }
    formData.append('name', params.firstname)
    formData.append('email', params.email)
    formData.append('contentHTML', params.contentHTML)
    formData.append('comment', JSON.stringify({
      ...params,
      uploadfile: null,
      type: typeTpl
    }))

    /* formData.append('lastname', params.lastname)
    formData.append('country', params.country)
    formData.append('city', params.city)
    formData.append('reason', params.reasonId)
    formData.append('message', params.message)
    formData.append('ordernumber', params.ordernumber)
    params.sku && formData.append('sku', params.sku)
    params.name && formData.append('name', params.name) */

    return Magento.post(Config.API.customerService.replace('{{storeViewCode}}', storeViewCode),
      formData,
      {
        headers: {
          'Authorization': `Bearer ${process.env.VUE_APP_MAGENTO_TOKEN}`,
          'Store': storeViewCode,
          'Content-Type': 'multipart/form-data'
        }
      })
  }
}

export default CustomerService
